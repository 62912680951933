@import '../../styles/variables';

.additional {
    position: relative;
    display: inline-block;
    width: 40%;
    height: 100%;
    vertical-align: top;

    .tab {
        flex: 1;
        padding: 5px 8px;
        color: #457eca;
        font-size: 12px;
        font-family: $medium-font;
        line-height: 14px;
        text-align: center;
        background: #f0f8ff;
        border-right: 1px solid #dae3f4;
        border-bottom: 1px solid #dae3f4;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
            color: #009fff;
        }

        &:last-child {
            border-right: unset;
        }

        &--selected {
            background-color: #fff;
            border-bottom: unset;

            &:hover {
                color: #457eca;
            }
        }

        &__list {
            position: absolute;
            top: -25px;
            right: -1px;
            left: -1px;
            z-index: 10;
            display: flex;
            box-sizing: border-box;
            padding: 0;
            overflow: hidden;
            list-style: none;
            background-color: #fff;
            border: 1px solid #dae3f4;
            border-bottom: unset;
            border-radius: 5px 5px 0 0;
        }

        &__panel {
            font-size: 14px;
            line-height: 17px;
        }
    }
}

.tabs {
    height: 100%;
    overflow-y: auto;
}
