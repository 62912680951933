@import '../../styles/variables';

.checkbox {
    position: absolute;
    left: -99999px;
    opacity: 0;

    &__container {
        display: inline-block;
        margin-bottom: 16px;
    }

    &__label {
        position: relative;
        display: inline-block;
        padding-left: 36px;
        color: #241f2c;
        font-size: 10px;
        font-family: $regular-font;
        line-height: 12px;
        cursor: pointer;

        &::after,
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
        }

        &::before {
            width: 26px;
            height: 26px;
            border: 1px solid #dae3f4;
            border-radius: 3px;
            transition: border-color 0.3s;
        }

        &::after {
            top: 4px;
            left: 4px;
            width: 20px;
            height: 20px;
        }

        &:hover {
            &::before {
                border-color: #009fff;
            }
        }

        a {
            color: #2a5298;
        }
    }

    &__error {
        margin-top: 8px;
        color: $red;
        font-size: 12px;
        font-family: $regular-font;
        line-height: 15px;
    }

    &:checked + .checkbox__label {
        &::before {
            border-color: #38c8a8;
        }

        &::after {
            background-image: url('../../assets/images/icons/checkmark.svg');
            background-position: center;
            background-size: 20px;
        }
    }
}
