@import '../../styles/variables';

:global {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        margin: 0;
        -webkit-appearance: none;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}

.input {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding: 15px;
    color: #3c4f5a;
    font-size: 14px;
    font-family: $regular-font;
    line-height: 17px;
    background: #fff;
    border: 1px solid #dae3f4;
    border-radius: 2px;
    outline: none;
    transition: border-color 0.3s;

    &__icon {
        display: inline-block;
        margin-right: 10px;
        color: #457eca;
        vertical-align: middle;
        transition: color 0.3s;
    }

    &__label {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        overflow: hidden;
        color: #3c4f5a;
        font-size: 14px;
        line-height: 17px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__error {
        margin-top: 8px;
        color: $red;
        font-size: 12px;
        font-family: $regular-font;
        line-height: 15px;
    }

    &__container {
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: 14px;
        padding-top: 26px;

        &--error {
            .input {
                border-color: $red;

                &__icon {
                    color: $red;
                }

                &:hover,
                &:focus,
                &:active {
                    border-color: $red;

                    & + .input__label {
                        .input__icon {
                            color: $red;
                        }
                    }
                }
            }
        }

        &--hidden {
            padding: 0;
        }
    }

    &:hover {
        border-color: #009fff;

        & + .input__label {
            .input__icon {
                color: #009fff;
            }
        }
    }

    &:focus,
    &:active {
        border-color: #38c8a8;

        & + .input__label {
            .input__icon {
                color: #38c8a8;
            }
        }
    }
}

textarea {
    min-width: 100%;
    max-width: 100%;
    min-height: 120px;
    max-height: 400px;
}
