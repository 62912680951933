@import './fonts.scss';
@import './variables';

* {
    margin: 0;
    padding: 0;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family:
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family:
        source-code-pro,
        Menlo,
        Monaco,
        Consolas,
        'Courier New',
        monospace;
}

h3 {
    margin-top: 0;
    margin-bottom: 12px;
    color: #457eca;
    font-size: 24px;
    font-family: $bold-font;
    line-height: 29px;
    letter-spacing: 0.02em;
}

.table {
    display: table;
    background: #fff;
    border: 1px solid #dae3f4;
    border-radius: 5px;

    &--full-width {
        box-sizing: border-box;
        width: 100%;
        table-layout: fixed;

        .tr:not(:first-child) {
            &:hover {
                background-color: $lightBlue;
            }
        }
    }

    .tr {
        display: table-row;
        text-decoration: none;
    }

    .td,
    .th {
        display: table-cell;
    }

    .th {
        padding: 10px 20px;
        color: #457eca;
        font-size: 14px;
        font-family: $bold-font;
        line-height: 17px;
        border-bottom: 1px solid #dae3f4;
    }

    .td {
        padding: 9px 20px 9px 20px;
    }
}

.react-tooltip-lite {
    box-sizing: border-box;
    max-width: 290px !important;
    padding: 8px 13px 11px;
    color: #3c4f5a;
    font-size: 12px;
    font-family: $medium-font;
    line-height: 14px;
    letter-spacing: 0.02em;
    white-space: pre-wrap;
    background: #fff;
    border: 1px solid #dae3f4;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.react-tooltip-lite-arrow {
    display: none;
}

.captcha__container {
    height: 78px;
    margin-top: 16px;
    transform: scale(0.95);
    transform-origin: left center;
}

@media screen and (max-width: 1199px) {
    .captcha__container {
        transform: scale(0.75);
    }
}

@media screen and (max-width: 991px) {
    .captcha__container {
        transform: scale(1.04);
    }
}

@media screen and (max-width: 767px) {
    .captcha__container {
        transform: scale(1);
    }
}

@media screen and (max-width: 362px) {
    .captcha__container {
        transform: scale(0.86);
    }
}
