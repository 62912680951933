@import '../../styles/variables';

.container {
    box-sizing: border-box;
    padding: 25px 40px 38px 35px;
    background: #fff;
    border: 1px solid #dae3f4;
    border-radius: 5px;
}

.project {
    color: #3c4f5a;
    font-weight: 500;
    font-size: 12px;
    font-family: $medium-font;
    line-height: 14px;
}

.basic {
    box-sizing: border-box;
    height: $height-container;
    margin-top: 48px;
    font-size: 0;
    background: #fff;
    border: 1px solid #dae3f4;
    border-radius: 5px 0 0 0;
}

.empty {
    position: relative;
    padding: 100px 10px;
    color: #9ab4c8;
    font-weight: 500;
    font-size: 14px;
    font-family: $medium-font;
    line-height: 17px;
    text-align: center;

    &__inner {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
}
