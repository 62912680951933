@import '../../styles/variables';

.button {
    padding: 15px 20px;
    font-size: 14px;
    font-family: $bold-font;
    line-height: 17px;
    text-align: center;
    border: unset;
    outline: none;
    cursor: pointer;

    &--full-width {
        width: 100%;
    }

    &--blue,
    &--green {
        position: relative;
        top: 0;
        left: 0;
        z-index: 2;
        color: #fff;
        text-transform: uppercase;
        transition: 0.1s linear;

        &.button--full-width {
            width: calc(100% - 3px);
        }

        &::after,
        &::before {
            position: absolute;
            z-index: 1;
            transition: 0.2s ease-in-out;
            content: '';
        }

        &::before {
            top: 100%;
            left: 2px;
            width: 99.9%;
            height: 3px;
            transform: skewX(45deg);
        }

        &::after {
            top: 2px;
            left: 100%;
            width: 3px;
            height: 99%;
            transform: skewY(45deg);
        }

        &:hover {
            top: 2px;
            left: 2px;

            &::before {
                width: calc(100% - 1px);
                height: 2px;
            }

            &::after {
                width: 2px;
                height: calc(100% - 1px);
            }
        }

        &:active,
        &:hover:active {
            top: 3px;
            left: 4px;
            box-shadow: unset;

            &::before {
                width: calc(100% - 3px);
                height: 0;
            }

            &::after {
                width: 0;
                height: calc(100% - 3px);
            }
        }

        &:disabled {
            color: #9ab4c8;
            background-color: #dae3f4;
            box-shadow: 3px 4px 3px #455056;
            cursor: default;
            user-select: none;
            pointer-events: none;

            &::before,
            &::after {
                background-color: #ced6e7;
            }
        }

        &.loading {
            top: 3px;
            left: 4px;
            box-shadow: unset;
            cursor: default;
            user-select: none;
            pointer-events: none;

            &::before {
                width: calc(100% - 3px);
                height: 0;
            }

            &::after {
                width: 0;
                height: calc(100% - 3px);
            }
        }
    }

    &--blue {
        background-color: #457eca;
        box-shadow: 3px 4px 3px #165b82;

        &::before {
            background-color: #2e66b0;
        }

        &::after {
            background-color: #5089d5;
        }

        &:hover {
            background-color: #009fff;
            box-shadow: 3px 3px 2px #165b82;
        }
    }

    &--green {
        background-color: #38c8a8;
        box-shadow: 3px 4px 3px #16826a;

        &::before {
            background-color: #21826c;
        }

        &::after {
            background-color: #39aa90;
        }

        &:hover {
            background-color: #25e2b8;
            box-shadow: 3px 3px 2px #16826a;
        }
    }

    &--with-border,
    &--with-border-square {
        box-sizing: border-box;
        padding: 9px 16px;
        color: #457eca;
        font-size: 14px;
        font-family: $medium-font;
        line-height: 17px;
        background: #fff;
        border: 1px solid #dae3f4;
        border-radius: 31px;
        transition: 0.3s;

        &:hover {
            color: #009fff;
            border-color: #009fff;
        }

        &:active,
        &:focus {
            color: #009fff;
            background: #e6f6ff;
            border: 1px solid #009fff;
        }
    }

    &--with-border-square {
        border-radius: 5px;
    }
}
