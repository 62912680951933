@import '../../styles/variables';

.container {
    margin-top: 20px;
    padding: 0 30px;
}

.list {
    margin: 20px 0;

    &__item {
        margin: 4px 0;

        &-text {
            display: inline-block;
            max-width: calc(100% - 45px);
            overflow: hidden;
            color: #457eca;
            font-size: 14px;
            font-family: $medium-font;
            line-height: 17px;
            text-overflow: ellipsis;
            vertical-align: middle;
        }

        &-remove {
            display: inline-block;
            width: 11px;
            height: 11px;
            margin-left: 16px;
            padding: 0;
            color: #dae3f4;
            line-height: 17px;
            vertical-align: middle;
            background-color: transparent;
            border: unset;
            cursor: pointer;
            transition: color 0.3s;

            &:hover {
                color: #009fff;
            }
        }
    }
}
