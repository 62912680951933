@import '../../styles/variables';

.container {
    margin-top: 36px;
}

.cell {
    font-size: 14px;
    font-family: $medium-font;
    line-height: 17px;
    letter-spacing: 0.02em;
}

.cell--name {
    color: #3c4f5a;
    white-space: nowrap;
}

.cell--email {
    overflow: hidden;
    color: #457eca;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.cell--status {
    white-space: nowrap;
}

.cell--1 {
    color: #38c8a8;
}

.cell--0 {
    color: #9ab4c8;
}

.cell--2 {
    color: #3c4f5a;
}

.button__container {
    margin-top: 16px;
    text-align: center;
}
