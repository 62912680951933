@import '../../styles/variables';

.container {
    margin-top: 42px;
}

.content {
    overflow-y: auto;
}

.event-cell {
    display: inline-block;
    max-width: 346px;
    overflow: hidden;
    color: #3c4f5a;
    font-size: 12px;
    font-family: $medium-font;
    line-height: 14px;
    letter-spacing: 0.02em;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}

.time-cell {
    display: inline-block;
    width: 23%;
    color: #9ab4c8;
    font-size: 12px;
    font-family: $regular-font;
    line-height: 14px;
    letter-spacing: 0.02em;
}

.table__empty {
    text-align: center;

    &-inner {
        display: inline-block;
        color: #9ab4c8;
        font-size: 14px;
        font-family: $regular-font;
        line-height: 17px;
        letter-spacing: 0.02em;
        vertical-align: middle;
    }

    &::after {
        display: inline-block;
        width: 1px;
        min-height: 480px;
        vertical-align: middle;
        content: '';
    }
}

.tooltip {
    overflow: hidden;
    text-overflow: ellipsis;
}
