@import '../../styles/variables';

.header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    text-align: left;

    &-left {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
    }

    &-right {
        float: right;
    }

    &-logo {
        position: relative;
        display: inline-block;
        margin-right: 3px;
        padding: 0;

        &__link {
            display: inline-block;
            vertical-align: middle;
        }

        &__icon {
            display: inline-block;
            margin-right: 18px;
            padding: 10px 0;

            img {
                vertical-align: middle;
            }
        }
    }

    &-list {
        display: inline-block;
        padding-left: 0;
        vertical-align: middle;
        list-style: none;

        &__item {
            display: inline-block;
            padding: 2px 13px;
            vertical-align: middle;
        }
    }

    &--white {
        .header {
            &-logo {
                &__text {
                    display: inline-block;
                    color: #fff;
                    font-size: 24px;
                    font-family: $bold-font;
                    line-height: 29px;
                    vertical-align: middle;
                }
            }

            &-divider {
                display: inline-block;
                width: 1px;
                height: 26px;
                margin: 0 6px 0 22px;
                vertical-align: middle;
                background-color: rgba($color: #fff, $alpha: 0.4);
            }

            &-list {
                &__link {
                    display: inline-block;
                    padding: 2px 5px 3px;
                    color: #fff;
                    font-size: 14px;
                    font-family: $regular-font;
                    line-height: 17px;
                    letter-spacing: 0.02em;
                    text-decoration: unset;
                    transition: background-color 0.3s linear;

                    &:hover {
                        background: rgba(255, 255, 255, 0.3);
                    }
                }
            }

            &-phone {
                position: relative;
                display: inline-block;
                margin-right: 25px;
                padding: 0;
                color: #fff;
                vertical-align: middle;

                &__link {
                    color: #fff;
                    font-size: 18px;
                    font-family: $bold-font;
                    letter-spacing: 0.02em;
                    text-decoration: none;
                }
            }

            &-login {
                display: inline-block;
                padding: 10px 25px;
                color: $blue;
                font-size: 12px;
                font-family: $bold-font;
                line-height: 14px;
                text-transform: uppercase;
                text-decoration: unset;
                background: #fff;
                border-radius: 5px;
                transition: background-color 0.3s;

                &:hover,
                &:active,
                &:focus {
                    background: #d2e9ff;
                }
            }
        }
    }

    &--blue {
        .header {
            &-logo {
                &__text {
                    display: inline-block;
                    color: #457eca;
                    font-size: 24px;
                    font-family: $bold-font;
                    line-height: 29px;
                    vertical-align: middle;
                }
            }

            &-divider {
                display: inline-block;
                width: 1px;
                height: 26px;
                margin: 0 6px 0 22px;
                vertical-align: middle;
                background-color: rgba($color: #457eca, $alpha: 0.4);
            }

            &-list {
                &__link {
                    color: #457eca;
                    font-size: 14px;
                    font-family: $regular-font;
                    line-height: 16px;
                    text-decoration: unset;
                    border-bottom: 2px solid rgba(69, 126, 202, 0);
                    transition: border-color 0.3s;

                    &--active {
                        border-bottom: 2px solid #457eca;
                    }

                    &:hover {
                        border-bottom: 2px solid #457eca;
                    }
                }
            }

            &-right {
                &__button {
                    display: inline-block;
                    margin-left: 36px;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .header {
        &--white,
        &--blue {
            .header-list {
                width: 100%;
                font-size: 0;

                &__item {
                    width: 100%;
                    padding: 0;
                    border-bottom: 1px solid #dae3f4;
                }

                &__link {
                    display: inline-block;
                    box-sizing: border-box;
                    width: 100%;
                    padding: 12px 10px 12px 24px;
                    color: #457eca;
                    font-size: 12px;
                    line-height: 15px;

                    span,
                    &-icon {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    &-icon {
                        margin-right: 9px;
                    }
                }
            }

            .header {
                &__username {
                    display: inline-block;
                    max-width: 78px;
                    margin-right: 23px;
                    overflow: hidden;
                    color: #457eca;
                    font-weight: bold;
                    font-size: 12px;
                    font-family: $bold-font;
                    line-height: 15px;
                    letter-spacing: 0.02em;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                }

                &-top {
                    padding: 8px 15px 8px 20px;
                    text-align: left;
                    border-bottom: 1px solid #dae3f4;

                    &__divider {
                        display: inline-block;
                        width: 1px;
                        height: 16px;
                        margin-right: 20px;
                        margin-left: 12px;
                        vertical-align: middle;
                        background-color: #457eca;
                    }
                }

                &-login {
                    padding: 9px 23px;
                    color: #fff;
                    background: #457eca;
                    border: none;
                    border-radius: 5px;
                }

                &-right {
                    &__button {
                        margin-left: 0;
                        padding: 6px 14px 7px 14px;
                    }
                }
            }

            .header-phone {
                margin-right: 0;
                font-size: 0;

                &__link {
                    padding: 8px 4px;
                    color: #457eca;
                    font-size: 12px;
                    line-height: 15px;
                }
            }
        }
    }
}
