@import '../../styles/variables';

.files {
    box-sizing: border-box;
    padding: 18px 25px;

    &__section {
        margin-bottom: 15px;

        &-title {
            margin-bottom: 17px;
            overflow: hidden;
            color: #3c4f5a;
            font-size: 14px;
            font-family: $bold-font;
            line-height: 17px;

            span {
                position: relative;

                &::after {
                    position: absolute;
                    top: 50%;
                    right: calc(-200px - 20px);
                    width: 200px;
                    height: 1px;
                    background-color: #dae3f4;
                    content: '';
                }
            }
        }
    }

    &__link {
        display: inline-block;
        width: 100%;
        margin: 2px 0;
        padding: 10px;
        color: #457eca;
        font-weight: 500;
        font-size: 12px;
        font-family: $medium-font;
        line-height: 14px;
        text-align: left;
        text-decoration: unset;
        background-color: transparent;
        border: unset;
        border-radius: 5px;
        outline: none;
        cursor: pointer;
        transition: background-color 0.3s;

        &-icon {
            display: inline-block;
            margin-right: 6px;
            vertical-align: middle;
        }

        &-drag {
            float: right;
            opacity: 0;
            transition: opacity 0.3s;
        }

        &:hover {
            background: #f0f8ff;

            .files__link-drag {
                opacity: 1;
            }
        }
    }
}
