// Fonts
$regular-font: 'GolosTextWebRegular', sans-serif;
$medium-font: 'GolosTextWebMedium', sans-serif;
$bold-font: 'GolosTextWebBold', sans-serif;

// Colors
$blue: #457eca;
$red: #db1444;
$lightBlue: #f0f8ff;

// Request content variables
$height-container: 385px;
