@import '../../styles/variables';

.container {
    display: inline-block;
    max-width: 100%;

    &--full-width {
        width: 100%;

        .root,
        .control {
            min-width: 100%;
        }
    }
}

.control {
    display: inline-block;
    max-width: 100%;
    padding: 4px 22px 4px 16px;
    cursor: pointer;
    transition: border-color 0.3s;

    &--rounded {
        color: #457eca;
        font-size: 18px;
        font-family: $bold-font;
        line-height: 22px;
        background: #f0f8ff;
        border: 2px solid #f0f8ff;
        border-radius: 100px;

        &:hover {
            border: 2px solid #457eca;
        }
    }

    &--default {
        padding: 13px 30px 15px 16px;
        color: #3c4f5a;
        font-size: 14px;
        font-family: $regular-font;
        line-height: 17px;
        border: 1px solid #dae3f4;
        border-radius: 2px;
        transition: border-color 0.3s;

        &:hover {
            border: 1px solid #009fff;
        }
    }
}

.select {
    &__error {
        margin-top: 8px;
        color: $red;
        font-size: 12px;
        font-family: $regular-font;
        line-height: 15px;
    }
}

.label {
    margin-bottom: 3px;
    color: #3c4f5a;
    font-weight: normal;
    font-size: 14px;
    font-family: $regular-font;
    line-height: 17px;
}

.placeholder {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.menu {
    box-sizing: border-box;

    &--rounded {
        left: 5%;
        width: 90%;
        margin-top: 2px;
        background: #fff;
        border: 1px solid #dae3f4;
        border-radius: 8px;
        box-shadow:
            0 0 50px rgba(0, 0, 0, 0.05),
            0 0 15px rgba(7, 9, 57, 0.05);

        & > div {
            color: #457eca;
            font-size: 12px;
            font-family: $regular-font;
            line-height: 14px;
        }
    }

    &--default {
        margin-top: -5px;
        background: #fff;
        border: 1px solid #38c8a8;
        border-radius: 0 0 2px 2px;
    }
}

:global {
    .is-open {
        :local {
            .control {
                &--default {
                    border-color: #38c8a8;
                }

                &--rounded {
                    border: 2px solid #457eca;
                }
            }
        }
    }

    .Dropdown-arrow-wrapper {
        position: absolute;
        top: 2px;
        right: 9px;
        height: auto;

        span {
            display: inline-block;
        }
    }
}

.root {
    display: inline-block;
    max-width: 100%;

    &--rounded {
        :global {
            .Dropdown-option.is-selected,
            .Dropdown-option:hover {
                color: #457eca;
                background: #e6f6ff;
                transition: background-color 0.3s;
            }
        }
    }

    &--default {
        :global {
            .Dropdown-option.is-selected,
            .Dropdown-option:hover {
                color: #3c4f5a;
                background: #e6f6ff;
                transition: background-color 0.3s;
            }
        }
    }
}
