@import '../../styles/variables';

.pagination {
    margin-top: 27px;
    margin-bottom: 18px;
    padding: 0;
    text-align: center;
    list-style: none;

    &__item {
        display: inline-block;
        margin: 0;
        color: #15253e;
        font-size: 14px;
        line-height: 16px;
        vertical-align: middle;
        border: 1px solid #dae3f4;
        border-left: none;

        &:first-child {
            overflow: hidden;
            border-left: 1px solid #dae3f4;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            &.active {
                background: #457eca;
                border-left: 1px solid #457eca;
            }
        }

        &:last-child {
            overflow: hidden;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        a {
            display: inline-block;
            padding: 5px 11px;
            color: #15253e;
            font-family: $regular-font;
            text-decoration: unset;

            &:hover {
                color: #0091e9;
            }
        }

        &--first,
        &--last,
        &--next,
        &--prev {
            a {
                display: inline-block;
                padding: 3px 9px 2px;
                color: #457eca;
                text-align: center;
                background-color: transparent;
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
                transition: background-color 0.3s, box-shadow 0.3s;

                svg {
                    width: 6px;
                    height: 18px;
                }
            }
        }

        &--prev {
            a {
                padding: 3px 12px 2px;
            }
        }

        &--next {
            a {
                padding: 4px 12px 1px;
            }
        }

        &--last {
            a {
                padding: 4px 8px 1px 10px;
            }
        }
    }
}

.disabled {
    svg {
        opacity: 0.5;
    }

    a {
        cursor: default;

        &:hover {
            color: #457eca;
        }
    }
}

.active {
    border-color: #457eca;

    a {
        color: #fff;
        font-family: $bold-font;
        background: #457eca;

        &:hover {
            color: #fff;
        }
    }
}
