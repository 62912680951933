@import '../../styles/variables';
@import '../../styles/mixin';

.footer {
    flex: 0 0 auto;
    min-height: 100px;
    padding-top: 80px;
    background: linear-gradient(111.41deg, #5e72b7 0.48%, #009fff 100%);

    &__top {
        margin-bottom: 75px;

        @include clearfix;

        &-left {
            float: left;
        }

        &-right {
            float: right;
        }
    }

    &-logo {
        position: relative;
        display: inline-block;
        margin-right: 3px;
        padding: 0;

        &__link {
            display: inline-block;
            vertical-align: middle;
        }

        &__icon {
            display: inline-block;
            margin-right: 18px;
            padding: 10px 0;

            img {
                vertical-align: middle;
            }
        }

        &__text {
            display: inline-block;
            color: #fff;
            font-size: 24px;
            font-family: $bold-font;
            line-height: 29px;
            vertical-align: middle;
        }
    }

    &-phone {
        position: relative;
        display: inline-block;
        margin-right: 25px;
        padding: 0;
        color: #fff;
        vertical-align: middle;

        &__link {
            display: inline-block;
            margin: 15px 0;
            color: #fff;
            font-size: 18px;
            font-family: $bold-font;
            letter-spacing: 0.02em;
            text-decoration: none;
        }
    }

    &__grid {
        display: grid;
        grid-column-gap: 25px;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(4, 1fr);
        margin-top: 60px;
        margin-bottom: 25px;

        &-category {
            min-width: 0;
            padding: 10px 0;
        }
    }

    &__item {
        &-heading {
            position: relative;
            display: block;
            margin: 0;
            padding: 0 0 0 30px;
            color: #fff;
            font-size: 12px;
            font-family: $bold-font;
            line-height: 14px;
            text-decoration: none;
            cursor: pointer;
            transition: 0.3s;

            & > .icon {
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 21px;
                transform: translate(0, -10%);
                transition: 0.3s;

                &--hov {
                    opacity: 0;
                }
            }

            &:hover {
                opacity: 0.5;

                & > .icon--unhov {
                    opacity: 0;
                }

                & > .icon--hov {
                    opacity: 1;
                }
            }
        }

        &-list {
            min-width: 0;
            padding: 0 0 0 30px;

            li {
                overflow: hidden;
                color: #fff;
                text-overflow: ellipsis;
                list-style: none;

                a {
                    color: #fff;
                    font-size: 12px;
                    font-family: $regular-font;
                    line-height: 14px;
                    white-space: nowrap;
                    text-decoration: none;
                    cursor: pointer;
                    transition: 0.3s;

                    img {
                        display: inline-block;
                        margin-right: 10px;
                        vertical-align: middle;
                    }

                    &:hover {
                        opacity: 0.5;
                    }

                    &.expand {
                        display: inline-block;
                        margin-top: 20px;
                        color: #fff;
                        font-size: 12px;
                        line-height: 15px;
                        text-decoration-line: underline;
                        opacity: 0.4;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    &__bottom {
        & > div ul {
            margin: 0;
            padding: 0;
            color: #254989;
            font-size: 12px;
            list-style: none;

            li {
                display: inline-block;
                margin-left: 15px;
                line-height: 12px;
                opacity: 1;

                &:first-child {
                    margin-left: 0;
                }
            }

            a {
                color: #fff;
                font-size: 12px;
                font-family: $regular-font;
                line-height: 14px;
                text-decoration: none;
                border-bottom: 1px solid rgba(255, 255, 255, 0);
                opacity: 0.5;
                transition: 0.3s;

                &:hover {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                }
            }
        }

        @include clearfix;
    }

    &__links {
        display: inline-block;
        width: 60%;
        padding: 7px 0;
        vertical-align: middle;

        li {
            position: relative;
            margin: 10px 0;

            &::after {
                position: absolute;
                right: -10px;
                color: #fff;
                opacity: 0.5;
                content: "/";
            }

            &:last-child {
                &::after {
                    content: unset;
                }
            }
        }

        @include clearfix;
    }

    &__lastchange {
        display: inline-block;
        width: 40%;
        padding: 15px 0;
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
        font-family: $regular-font;
        line-height: 14px;
        text-align: right;
        vertical-align: middle;
    }

    &--auth {
        .footer {
            &__lastchange {
                padding: 10px 0;
                color: #9ab4c8;
            }
        }
    }
}

@media only screen and (max-width: 1135px) {
    .footer__grid {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 800px) {
    .footer__grid {
        display: grid;
        grid-column-gap: 15px;
        grid-template-columns: repeat(2, 1fr);
        max-width: 600px;
        margin: 30px auto;
        padding: 0 15px;
    }
}
