@import '../../styles/variables';

.restore-pass {
    &__title {
        margin: 0 -26px 32px;
        color: $blue;
        font-size: 24px;
        font-family: $bold-font;
        line-height: 29px;
        text-align: center;

        &--first,
        &--second,
        &--third {
            margin-bottom: 8px;
        }
    }

    &__text {
        margin: 8px -38px 40px;
        color: #3c4f5a;
        font-size: 14px;
        font-family: $regular-font;
        line-height: 18px;
        text-align: center;
    }

    &__button {
        margin-top: 32px;
    }

    &__captcha {
        height: 78px;
        margin-top: 16px;
        // overflow: hidden;
    }
}

.tab__list {
    display: none;
}

.progress__container {
    overflow: hidden;
    border-radius: 5px 5px 0 0;
}

@media screen and (max-width: 991px) {
    .restore-pass {
        &__title {
            margin: 0 0 32px;
        }

        &__text {
            margin: 0 0 40px;
        }
    }
}
