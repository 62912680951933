@import '../../styles/variables';

.toggler {
    position: relative;
    padding: 0 12px 0 10px;
    color: #457eca;
    background-color: unset;
    border: unset;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;

    &__name {
        display: inline-block;
        margin-right: 4px;
        font-size: 14px;
        font-family: $bold-font;
        line-height: 17px;
        vertical-align: middle;
    }

    &__icon {
        position: absolute;
        top: 7px;
        right: 0;
        display: inline-block;
        width: 9px;
        height: 6px;
        transition: transform 0.3s;

        svg {
            display: block;
        }
    }

    &:hover {
        color: #009fff;
    }
}

.container {
    position: relative;
    display: inline-block;
    min-width: 106px;
    text-align: center;
    vertical-align: middle;

    &__inner {
        position: absolute;
        bottom: calc(-100% - 18px);
        left: 50%;
        z-index: 100;
        display: none;
        box-sizing: border-box;
        overflow: hidden;
        background: #fff;
        border: 1px solid #dae3f4;
        border-radius: 8px;
        box-shadow:
            0 0 50px rgba(0, 0, 0, 0.05),
            0 0 15px rgba(7, 9, 57, 0.05);
        transform: translateX(-50%);
    }

    &--open {
        .container {
            &__inner {
                display: block;
            }
        }

        .toggler {
            color: #009fff;

            &__icon {
                transform: rotate(180deg);
            }
        }
    }
}

.out {
    padding: 9px 32px;
    color: #457eca;
    font-size: 12px;
    font-family: $regular-font;
    line-height: 14px;
    background-color: transparent;
    border: unset;
    outline: none;
    cursor: pointer;
}
