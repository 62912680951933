@import '../../styles/variables';

.checklists {
    box-sizing: border-box;
    padding: 30px;
}

.checklist {
    &__section {
        margin-bottom: 26px;

        &-title {
            margin-bottom: 13px;
            overflow: hidden;
            color: #3c4f5a;
            font-size: 14px;
            font-family: $bold-font;
            line-height: 17px;
        }
    }

    &__item {
        display: inline-block;
        width: 100%;
        margin: 0;
        padding: 5px 0;
        color: #3c4f5a;
        font-weight: 500;
        font-size: 0;
        font-family: $medium-font;
        text-decoration: unset;
        background-color: transparent;
        border-radius: 5px;
        transition: background-color 0.3s;

        &--complete {
            .checklist__item-text {
                text-decoration: line-through;
            }
        }

        &-text {
            display: inline-block;
            box-sizing: border-box;
            width: 90%;
            padding-left: 6px;
            font-size: 14px;
            line-height: 17px;
            vertical-align: middle;
        }

        &-icon {
            display: inline-block;
            box-sizing: border-box;
            width: 10%;
            height: 15px;
            padding: 3px 0;
            color: #dae3f4;
            vertical-align: middle;
        }
    }
}
