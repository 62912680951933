@import '../../styles/variables';

.login-page {
    margin: 128px -20px;

    &__inner {
        padding: 36px 60px 52px;
        background: #fff;
        border: 1px solid #dae3f4;
        border-radius: 5px;
    }

    &__title {
        margin: 0 0 32px 0;
        color: $blue;
        font-size: 24px;
        font-family: $bold-font;
        line-height: 29px;
        text-align: center;
    }

    &__bottom {
        margin-top: 24px;
        color: #fff;
        font-size: 14px;
        font-family: $regular-font;
        line-height: 17px;
        text-align: center;

        &-link {
            display: inline-block;
            margin-top: 10px;
            color: #fff;
            font-family: $medium-font;
            text-decoration: unset;
        }
    }
}

@media screen and (max-width: 991px) {
    .login-page {
        margin: 120px 0;

        &__inner {
            padding: 0 15px;
            border: unset;
        }

        &__bottom {
            margin-top: 60px;
            color: #457eca;

            &-link {
                color: #457eca;
                font-family: $bold-font;
            }
        }
    }
}
