@import '../../styles/variables';

.container {
    margin-top: 42px;
}

.list {
    margin: -4px 0 0 -4px;
    padding: 0;
    font-size: 0;
    list-style: none;
}

.item {
    display: inline-block;
    box-sizing: border-box;
    width: 33.333%;
    padding: 4px;
    vertical-align: top;

    &__inner {
        padding: 13px 30px 14px 16px;
        background: #fff;
        border: 1px solid #dae3f4;
        border-radius: 5px;
        transition: background-color 0.3s;
    }

    &__link {
        display: inline-block;
        width: 100%;
        text-decoration: unset;
    }

    &__title {
        margin-bottom: 4px;
        overflow: hidden;
        color: #457eca;
        font-size: 16px;
        font-family: $bold-font;
        line-height: 19px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__info {
        display: inline-block;
        color: #3c4f5a;
        font-size: 12px;
        font-family: $medium-font;
        line-height: 14px;
        vertical-align: middle;
    }

    &__count {
        display: inline-block;
        margin-right: 5px;
        margin-left: 9px;
        color: #457eca;
        font-size: 12px;
        line-height: 14px;
        vertical-align: middle;
    }

    &__icon {
        display: inline-block;
        vertical-align: middle;
    }

    &:hover {
        .item {
            &__inner {
                background: #f0f8ff;
            }
        }
    }
}
