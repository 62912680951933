@import '../../styles/variables';

.container {
    margin-top: 30px;
    margin-bottom: 32px;
}

.title {
    margin-top: 0;
    margin-bottom: 12px;
    color: #dae3f4;
    font-size: 18px;
    font-family: $bold-font;
    line-height: 22px;
}

.list {
    position: relative;
    padding-left: 18px;

    &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 4px;
        background-color: #f0f8ff;
        content: '';
    }
}

.item {
    padding: 4px 0;
    list-style: none;

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }
}

.button {
    padding: 10px 12px;
    font-size: 14px;
    font-family: $medium-font;
    line-height: 17px;
    background: #f0f8ff;
    border: unset;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
        background-color: #d9edff;
    }

    .icon {
        display: inline-block;
        margin-left: 7px;
        vertical-align: middle;
    }
}
