@import '../../styles/variables';

.table {
    width: 100%;

    .tr {
        display: block;
        padding: 15px 20px;
        font-size: 0;

        &__head {
            padding: 8px 20px;
        }

        &__item {
            position: relative;
            box-sizing: border-box;
            background: #fff;
            border: 1px solid #dae3f4;
            border-radius: 5px;

            &--new {
                animation: leaves 1s ease-in-out infinite alternate;
            }
        }
    }

    .th {
        color: #457eca;
        font-size: 14px;
        font-family: $bold-font;
        line-height: 17px;
    }

    .td {
        color: #457eca;
        font-size: 14px;
        font-family: $medium-font;
        line-height: 17px;

        &.cell {
            &--deadline {
                font-size: 14px;
                font-family: $regular-font;
                line-height: 18px;
            }
        }
    }

    .th,
    .td {
        display: inline-block;
        box-sizing: border-box;
        vertical-align: middle;

        &:first-child {
            width: 40%;
        }

        &:nth-child(2) {
            width: 35%;
            padding-left: 15px;
        }

        &:nth-child(3) {
            width: 15%;
            padding-left: 15px;
        }

        &:nth-child(4) {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 54px;
            text-align: center;
            cursor: move;

            svg {
                height: 100%;
            }
        }
    }
}

.item__wrapper {
    padding-bottom: 8px;

    .icon {
        color: #dae3f4;
    }

    &--dragged {
        .tr__item {
            box-shadow: 0 0 10px rgba(40, 54, 75, 0.25);
        }

        .icon {
            color: #009fff;
        }
    }
}

.project {
    display: block;
    margin-bottom: 4px;
    color: #3c4f5a;
    font-size: 12px;
    font-family: $medium-font;
    line-height: 14px;
}

.date {
    margin-bottom: 2px;
    color: #9ab4c8;
    font-size: 12px;
}

.icon {
    text-align: right;

    &-inline {
        display: inline-block;
        margin-right: 8px;
        vertical-align: middle;
    }
}

.is-empty {
    display: inline-block;
    width: 40px;
    height: 1px;
    vertical-align: middle;
    background-color: #dae3f4;
}

.loader {
    height: 100%;

    & > div {
        display: inline-block;
        width: 100%;
    }

    &::after {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        content: '';
    }
}

@keyframes leaves {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.03);
    }
}
