@import '../../styles/variables';

$menu-width: 272px;

.menu {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    padding: 7px 16px;
    background: #457eca;

    &-logo {
        display: inline-block;

        &__text {
            display: inline-block;
            color: #fff;
            font-size: 14px;
            font-family: $bold-font;
            line-height: 17px;
            letter-spacing: 0.02em;
            vertical-align: middle;
        }

        &__icon {
            display: inline-block;
            max-height: 26px;
            margin-right: 9px;
            padding: 0;
            vertical-align: middle;

            img {
                vertical-align: middle;
            }
        }
    }

    &__button {
        position: absolute;
        top: 0;
        right: 0;
        padding: 12px 12px;
        background-color: transparent;
        border: unset;
        outline: none;
    }

    &__container {
        position: fixed;
        top: 40px;
        right: -$menu-width;
        bottom: 0;
        left: auto;
        z-index: 900;
        width: $menu-width;
        background-color: #fff;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
        transition: right 0.3s;
    }

    &--is-open {
        .menu {
            &__container {
                right: 0;
            }
        }
    }
}
