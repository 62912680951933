@import '../../styles/variables';

.content {
    position: relative;
    z-index: 5;
    display: inline-block;
    min-width: 290px;
    max-width: 570px;
    margin: 0;
    background: #fff;
    border-radius: 2px;
    box-shadow:
        0 0 50px rgba(0, 0, 0, 0.05),
        0 0 15px rgba(7, 9, 57, 0.05);
    opacity: 0;
    pointer-events: none;

    &__inner {
        position: relative;
        z-index: 1;
        min-height: 200px;
    }
}

.dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 100%;
    height: 100%;
    overflow: none;
    text-align: center;
    pointer-events: none;

    &__header {
        position: relative;
        z-index: 4;
        padding: 10px;
        padding-top: 55px;
        text-align: center;
    }

    &__title {
        margin: 0;
        padding: 0;
        color: #457eca;
        font-size: 24px;
        font-family: $bold-font;
        line-height: 29px;
    }

    &__body {
        position: relative;
        z-index: 2;
        padding: 10px 40px;
    }

    &__footer {
        position: relative;
        z-index: 5;
        box-sizing: border-box;
        width: 100%;
        padding: 25px 10px;

        &--default {
            padding-top: 55px;
        }
    }

    &--open {
        z-index: 100;
        overflow: auto;
        background-color: rgba($color: #fff, $alpha: 0.7);
        opacity: 1;
        transition: opacity 0.4s;
        pointer-events: auto;

        .content {
            top: calc(50% - 100px);
            margin: 100px 0;
            animation-name: anim-open;
            animation-duration: 0.7s;
            animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
            animation-fill-mode: forwards;
            pointer-events: auto;
        }
    }

    &--close {
        opacity: 1;
        transition: opacity 0.4s;

        .content {
            animation-name: anim-close;
            animation-duration: 0.4s;
            animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
            animation-fill-mode: forwards;
            pointer-events: auto;
        }
    }

    &--sm {
        .content {
            width: 423px;
            min-width: 423px;
        }
    }

    &--md {
        .content {
            width: 495px;
            min-width: 495px;
        }
    }

    &--lg {
        .content {
            width: 670px;
            min-width: 670px;
        }
    }

    &--xlg {
        .content {
            width: 845px;
            min-width: 845px;
        }
    }
}

.close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 10;
    width: 32px;
    height: 32px;
    color: #e1e4e8;
    font-size: 0;
    background-color: #fff;
    border: unset;
    outline: none;
    transform: rotate(45deg);
    cursor: pointer;
    opacity: 1;
    transition: all 0.5s;

    &:hover {
        color: #009fff;
    }

    &:active {
        color: #4d4d4d;
    }

    svg {
        transform: rotate(-45deg);
    }
}

.footer {
    &__button {
        min-width: 309px;
    }
}

:global {
    .dialog-overlay {
        overflow: hidden;
    }
}

@keyframes anim-open {
    0% {
        transform: translate(0, -800px);
        opacity: 0;
    }

    100% {
        transform: translate(0, -50%);
        opacity: 1;
    }
}

@keyframes anim-close {
    0% {
        transform: translate(0, -50%);
        opacity: 1;
    }

    100% {
        transform: translate(0, 100px);
        opacity: 0;
    }
}

@keyframes anim-elem {
    0% {
        transform: translate(0, -100px);
        opacity: 0;
    }

    100% {
        transform: translate(0, -50%);
        opacity: 1;
    }
}
