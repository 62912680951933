.page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    // min-height: calc(100vh + 1px);

    &__content {
        flex: 1 0 auto;
    }

    &--blue {
        background-image: linear-gradient(115.18deg, #ec2f4b -100.48%, #009fff 68.51%);
    }

    &--white {
        background-image: url('../../assets/images/pattern.png');
    }
}

.button-error {
    padding: 40px 20px;
}

@media screen and (max-width: 991px) {
    .page-wrapper {
        &--blue {
            background: #fff;
        }
    }
}
