.progress {
    display: flex;

    &__item {
        flex: 1;
        height: 7px;
        background-color: #dae3f4;

        &--filled {
            background-color: #38c8a8;
        }
    }
}
