@import '../../styles/variables';

$padding-size: 15px;
$textarea-height: 40px;

.chat {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 60%;
    height: 100%;
    padding: $padding-size;
    overflow: hidden;
    vertical-align: top;
    background: #f0f8ff;
    border-right: 1px solid #dae3f4;

    &__content {
        color: #3c4f5a;
        font-size: 14px;
        line-height: 18px;

        &-inner {
            position: absolute;
            top: 0;
            right: 0;
            bottom: $textarea-height;
            left: 0;
            padding: $padding-size;
            overflow: auto;
        }
    }

    &__loader {
        text-align: center;

        &-inner,
        &::after {
            display: inline-block;
            vertical-align: middle;
        }

        &::after {
            height: 100%;
            min-height: calc(#{$height-container} - #{$padding-size} - #{$padding-size});
            content: '';
        }

        &-text {
            color: #457eca;
            font-weight: 500;
            font-size: 14px;
            font-family: $medium-font;
            line-height: 18px;
        }
    }

    &__error {
        text-align: center;

        &-inner,
        &::after {
            display: inline-block;
            vertical-align: middle;
        }

        &::after {
            height: 100%;
            min-height: calc(#{$height-container} - #{$padding-size} - #{$padding-size});
            content: '';
        }

        &-text {
            margin-bottom: 16px;
            color: #ff6a6a;
            font-weight: 500;
            font-size: 14px;
            font-family: $medium-font;
            line-height: 18px;
        }
    }

    &__textarea {
        box-sizing: border-box;
        min-height: $textarea-height;
        max-height: 190px;
        padding: 10px 40px;
        color: #000;
        font-weight: 300;
        font-size: 14px;
        font-family: $regular-font;
        font-style: normal;
        line-height: 18px;
        vertical-align: bottom;
        background-color: #fff;
        border: none;
        outline: none;
        transition: background-color 0.3s;
        resize: none;

        &-container {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            border-top: 1px solid #dae3f4;
        }

        &-inner {
            position: relative;
        }

        &:disabled {
            background-color: #f8f8f8;
        }
    }

    &__button {
        position: absolute;
        bottom: 0;
        width: 40px;
        height: 40px;
        color: #9ab4c8;
        background-color: transparent;
        border: unset;
        outline: none;
        cursor: pointer;
        transition: color 0.3s;

        svg {
            display: inline-block;
            vertical-align: middle;
        }

        &::after {
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            content: '';
        }

        &--upload {
            left: 0;
        }

        &--send {
            right: 0;
            color: #457eca;
        }

        &:hover,
        &:active,
        &:focus {
            color: #009fff;
        }
    }

    &__message {
        &-date-title {
            display: inline-block;
            width: 100%;
            margin-top: 15px;
            margin-bottom: 15px;
            text-align: center;

            span {
                padding: 4px 13px;
                color: #3c4f5a;
                font-weight: 300;
                font-family: $regular-font;
                background-color: #d9e8f4;
                border-radius: 500px;
            }
        }

        &-item {
            margin-bottom: 6px;
            text-align: left;

            &-inner {
                display: inline-block;
                box-sizing: border-box;
                max-width: 80%;
                padding: 3px 10px 5px;
                background: #fff;
                border: 1px solid #dae3f4;
                border-radius: 5px;
            }

            &-author {
                color: #457eca;
                font-weight: 500;
                font-size: 10px;
                font-family: $medium-font;
                line-height: 18px;
                text-align: left;
            }

            &-text {
                float: left;
                color: #3c4f5a;
                font-weight: 300;
                font-size: 12px;
                font-family: $regular-font;
                line-height: 16px;
                text-align: left;
            }

            &-time {
                float: right;
                margin-top: 5px;
                margin-left: 10px;
                color: #3c4f5a;
                font-weight: 300;
                font-size: 10px;
                font-family: $regular-font;
                line-height: 18px;
            }

            &--author {
                &.chat__message {
                    &-item {
                        text-align: right;
                    }
                }

                .chat__message {
                    &-item {
                        &-inner {
                            background: #dbf1ff;
                        }
                    }
                }
            }
        }

        &-loader {
            text-align: center;

            span {
                color: #457eca;
                font-weight: 500;
                font-size: 12px;
                font-family: $medium-font;
                line-height: 18px;
            }
        }
    }

    &__empty {
        color: #9ab4c8;
        font-weight: 500;
        font-size: 14px;
        font-family: $regular-font;
        line-height: 17px;
        text-align: center;

        &-inner {
            display: inline-block;
            vertical-align: middle;
        }

        &::after {
            display: inline-block;
            min-height: calc(#{$height-container} - #{$textarea-height} - 32px);
            vertical-align: middle;
            content: '';
        }
    }
}
