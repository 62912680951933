@import '../../styles/variables';

.form {
    margin: 44px;
    text-align: left;
}

.text {
    color: #3c4f5a;
    font-size: 14px;
    font-family: $regular-font;
    line-height: 18px;
}

.button {
    margin-top: 24px;
}
