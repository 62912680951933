@font-face {
    font-weight: normal;
    font-family: 'GolosTextWebRegular';
    font-style: normal;
    src: url('../assets/fonts/Golos/Golos%20Text_Regular.eot');
    src:
        url('../assets/fonts/Golos/Golos%20Text_Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Golos/Golos%20Text_Regular.woff2') format('woff2'),
        url('../assets/fonts/Golos/Golos%20Text_Regular.woff') format('woff'),
        url('../assets/fonts/Golos/Golos%20Text_Regular.ttf') format('truetype');
}

@font-face {
    font-weight: normal;
    font-family: 'GolosTextWebMedium';
    font-style: normal;
    src: url('../assets/fonts/Golos/Golos%20Text_Medium.eot');
    src:
        url('../assets/fonts/Golos/Golos%20Text_Medium.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Golos/Golos%20Text_Medium.woff2') format('woff2'),
        url('../assets/fonts/Golos/Golos%20Text_Medium.woff') format('woff'),
        url('../assets/fonts/Golos/Golos%20Text_Medium.ttf') format('truetype');
}

@font-face {
    font-weight: normal;
    font-family: 'GolosTextWebBold';
    font-style: normal;
    src: url('../assets/fonts/Golos/Golos%20Text_Bold.eot');
    src:
        url('../assets/fonts/Golos/Golos%20Text_Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Golos/Golos%20Text_Bold.woff2') format('woff2'),
        url('../assets/fonts/Golos/Golos%20Text_Bold.woff') format('woff'),
        url('../assets/fonts/Golos/Golos%20Text_Bold.ttf') format('truetype');
}
