.loader {
    position: relative;
    width: 100px;
    margin: 0 auto;

    span {
        display: inline-block;

        &:nth-child(1) {
            animation: grow 1s ease-in-out infinite;
        }

        &:nth-child(2) {
            animation: grow 1s ease-in-out 0.15s infinite;
        }

        &:nth-child(3) {
            animation: grow 1s ease-in-out 0.3s infinite;
        }

        &:nth-child(4) {
            animation: grow 1s ease-in-out 0.45s infinite;
        }
    }
}

@keyframes grow {
    0%,
    100% {
        -webkit-transform: scaleY(1);
        -ms-transform: scaleY(1);
        -o-transform: scaleY(1);
        transform: scaleY(1);
    }

    50% {
        -webkit-transform: scaleY(1.8);
        -ms-transform: scaleY(1.8);
        -o-transform: scaleY(1.8);
        transform: scaleY(1.8);
    }
}
