@import '../../styles/variables';

.container {
    box-sizing: border-box;
    margin: 42px 0;
    padding: 29px 36px 29px 31px;
    background: #fff;
    border: 1px solid #dae3f4;
    border-radius: 5px;
}

.project {
    margin: 0;
    color: #3c4f5a;
    font-weight: 500;
    font-size: 12px;
    font-family: $medium-font;
    line-height: 14px;
}

.title {
    margin: 2px 0 16px;
    color: #457eca;
    font-size: 24px;
    font-family: $bold-font;
    line-height: 29px;

    span {
        display: inline-block;
        vertical-align: middle;
    }

    &__status {
        margin-left: 18px;
        font-size: 14px;
        font-family: $medium-font;
        line-height: 17px;
    }
}

.description {
    margin: 0 0 22px;
    padding: 0;
    color: #3c4f5a;
    font-weight: normal;
    font-size: 16px;
    font-family: $regular-font;
    line-height: 23px;
    white-space: pre-wrap;
}

.document {
    &__item {
        margin: 5px;
        color: #457eca;
        font-size: 16px;
        font-family: $medium-font;
        line-height: 19px;

        &-icon {
            margin-right: 6px;
        }
    }
}

.info__item {
    margin-bottom: 24px;

    &-title {
        margin-bottom: 6px;
        color: #457eca;
        font-weight: 700;
        font-size: 16px;
        font-family: $bold-font;
        line-height: 19px;
        letter-spacing: 0.02em;
    }

    &-value {
        color: #3c4f5a;
        font-size: 16px;
        font-family: $regular-font;
        line-height: 19px;
        letter-spacing: 0.02em;
    }

    a {
        color: #457eca;
        font-size: 14px;
        font-family: $medium-font;
        line-height: 17px;
    }
}
