@import '../../styles/variables';

.login {
    &__button {
        margin-top: 32px;
    }

    &__inner-bottom {
        margin-top: 13px;
        text-align: center;
    }

    &__link-restore {
        color: #9ab4c8;
        font-size: 14px;
        font-family: $regular-font;
        line-height: 17px;
        text-decoration: unset;
    }
}
