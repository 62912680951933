@import '../../styles/variables';

.empty {
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    background: #fff;
    border: 1px solid #dae3f4;
    border-radius: 5px;

    &__inner {
        &::after {
            display: inline-block;
            min-height: 500px;
            vertical-align: middle;
            content: '';
        }
    }

    &__content {
        display: inline-block;
        width: 100%;
        color: #9ab4c8;
        font-size: 14px;
        font-family: $medium-font;
        line-height: 17px;
        text-align: center;
        vertical-align: middle;
    }

    &__button {
        margin-top: 18px;
        margin-bottom: 18px;
    }
}
