@import '../../styles/variables';

.text {
    margin-bottom: 32px;
    color: #3c4f5a;
    font-weight: normal;
    font-size: 14px;
    font-family: $regular-font;
    line-height: 18px;
    text-align: center;
}

.container {
    margin-bottom: 50px;

    &__button {
        margin-top: 32px;
        text-align: right;
    }
}

.form {
    text-align: left;
}
