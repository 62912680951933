@import '../../styles/variables';

.deadlines {
    box-sizing: border-box;
    padding: 28px 10px 20px 27px;

    &__inner {
        position: relative;

        &::before {
            position: absolute;
            top: 5%;
            left: 4px;
            width: 1px;
            height: 90%;
            background-color: #dae3f4;
            content: '';
        }
    }

    &__item {
        margin-bottom: 23px;
        color: #9ab4c8;
        font-family: $regular-font;

        &-info {
            display: inline-block;
            width: 90%;
            vertical-align: middle;
        }

        &-date {
            font-size: 12px;
            line-height: 18px;
        }

        &-title {
            font-size: 14px;
            line-height: 19px;
        }

        &-icon {
            position: relative;
            display: inline-block;
            box-sizing: border-box;
            width: 10%;
            padding: 8px 0;
            vertical-align: middle;
            background-color: #fff;
        }

        &--success {
            .deadlines__item-title,
            .deadlines__item-icon {
                color: #38c8a8;
            }
        }

        &--failed {
            .deadlines__item-title,
            .deadlines__item-icon {
                color: #ff6a6a;
            }
        }

        &--normal {
            .deadlines__item-title,
            .deadlines__item-icon {
                color: #457eca;
            }
        }
    }
}
