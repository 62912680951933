@import '../../styles/variables';

.restore-pass-page {
    margin: 128px -20px;

    &__inner {
        padding: 36px 50px 52px;
        background: #fff;
        border: 1px solid #dae3f4;
        border-radius: 0 0 5px 5px;
    }

    &__title {
        margin: 0 0 32px 0;
        color: $blue;
        font-size: 24px;
        font-family: $bold-font;
        line-height: 29px;
        text-align: center;

        &--second,
        &--third {
            margin-bottom: 8px;
        }
    }
}

.tab__list {
    display: none;
}

.progress__container {
    overflow: hidden;
    border-radius: 5px 5px 0 0;
}

@media screen and (max-width: 991px) {
    .restore-pass-page {
        margin: 66px 0 120px 0;

        &__inner {
            margin-top: 52px;
            padding: 0 15px;
            border: unset;
        }

        &__bottom {
            margin-top: 60px;
            color: #457eca;

            &-link {
                color: #457eca;
                font-family: $bold-font;
            }
        }
    }

    .progress {
        &__container {
            position: fixed;
            top: 40px;
            right: 0;
            left: 0;
            z-index: 100;
            border-radius: 0;
        }
    }
}
