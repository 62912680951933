@import '../../styles/variables';

.container {
    margin: 30px 0 55px;
}

.heading {
    color: #457eca;
    font-size: 14px;
    font-family: $bold-font;
    line-height: 17px;
}

.description {
    overflow: hidden;
    color: #3c4f5a;
    font-size: 12px;
    font-family: $regular-font;
    line-height: 14px;
    text-overflow: ellipsis;
}

.table {
    width: 100%;

    .tr {
        display: block;
        padding: 8px 20px;
        font-size: 0;

        &__item {
            box-sizing: border-box;
            margin-bottom: 8px;
            background: #fff;
            border: 1px solid #dae3f4;
            border-left: 4px solid #457eca;
            border-radius: 5px;

            .status {
                color: #457eca;
            }

            &--1 {
                border-left: 4px solid #457eca;

                .status {
                    color: #457eca;
                }
            }

            &--2 {
                border-left: 4px solid #ffba47;

                .status {
                    color: #ffba47;
                }
            }

            &--3 {
                border-left: 4px solid #009fff;

                .status {
                    color: #009fff;
                }
            }

            &--4 {
                border-left: 4px solid #38c8a8;

                .status {
                    color: #38c8a8;
                }
            }
        }
    }

    .th {
        color: #457eca;
        font-size: 14px;
        font-family: $bold-font;
        line-height: 17px;
    }

    .td {
        color: #457eca;
        font-size: 14px;
        font-family: $medium-font;
        line-height: 17px;
    }

    .th,
    .td {
        display: inline-block;
        box-sizing: border-box;
        vertical-align: middle;

        &:first-child {
            width: 67%;
        }

        &:nth-child(2) {
            width: 18%;
            padding-left: 15px;
        }

        &:nth-child(3) {
            width: 15%;
            padding-left: 15px;
        }
    }

    .content {
        max-width: 520px;
    }
}

.status {
    font-size: 14px;
    font-family: $medium-font;
    line-height: 17px;
}

.sum {
    color: #3c4f5a;
    font-size: 14px;
    font-family: $regular-font;
    line-height: 17px;
    letter-spacing: 0.02em;
}
